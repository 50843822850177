body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
}

html,
#root,
.App {
    height: 100%;
    width: 100%;
}

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
} */

@font-face {
    font-family: Circular;
    src: url('./assets/fonts/CircularStd-Bold.woff');
}

@font-face {
    font-family: HKGrotesk;
    font-weight: 700;
    src: url('./assets/fonts/HKGrotesk-Bold.woff2');
}

@font-face {
    font-family: HKGrotesk;
    font-weight: 600;
    src: url('./assets/fonts/HKGrotesk-SemiBold.woff2');
}
@font-face {
    font-family: HKGrotesk;
    font-weight: 500;
    src: url('./assets/fonts/HKGrotesk-Medium.woff2');
}
@font-face {
    font-family: HKGrotesk;
    font-weight: 400;
    src: url('./assets/fonts/HKGrotesk-Regular.woff2');
}
@font-face {
    font-family: HKGrotesk;
    font-weight: 300;
    src: url('./assets/fonts/HKGrotesk-Light.woff2');
}
